<template>
  <div>
    <el-upload
      class="avatar-uploader"
      :action="UPLOAD_PICTURE_URL"
      :show-file-list="false"
      :headers="headers"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <video v-if="videoUrl" class="avatar" controls>
        <source
          :src="'http://yxyxa.yslts.com/' + videoUrl"
          type="video/mp4"
        />
      </video>
      <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
      <template #tip>
        <div class="text-red">视频推荐: 50MB 以下的 MP4 / AVI 格式的视频</div>
      </template>
    </el-upload>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue'
import { useVModel } from '@vueuse/core'
import { UPLOAD_VIDEO } from '@/api/article'
import { ElMessage } from 'element-plus'
import { userStore } from '@/store'
const props = defineProps({
  modelValue: {
    type: String,
    default: '',
    required: true
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const videoUrl = useVModel(props) // 得到一个响应式的数据，可以直接修改

// action 路径
const UPLOAD_PICTURE_URL = ref(UPLOAD_VIDEO)

// token 追加
const store = userStore()
const headers = computed(() => {
  return { Authorization: `Bearer ${store.token}` }
})

// 上传之后
const handleAvatarSuccess = (response, uploadFile) => {
  videoUrl.value = response.data.path
}
// 上传之前
const beforeAvatarUpload = (rawFile) => {
  const types = ['video/mp4', 'image/avi']
  if (!types.includes(rawFile.type)) {
    ElMessage.error('上传的图片只能是 MP4 , AVI  的格式')
    return false
  } else if (rawFile.size / 1024 / 1024 > 50) {
    ElMessage.error('视频最大不可以超过 50MB!')
    return false
  }
  return true
}
</script>
<style scoped>
.avatar-uploader .avatar {
  width: 450px;
  height: 200px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 450px;
  height: 200px;
  text-align: center;
}

.text-red {
  color: red;
}
</style>
