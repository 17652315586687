<template>
  <div>
    <h2 class="logo-title">{{ title }}</h2>
    <el-divider />
    <el-card class="box-card">
      <el-form
        label-width="80px"
        ref="ruleFormRef"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" />
        </el-form-item>

        <el-form-item label="所属分类" prop="class_id">
          <el-select
            v-model="form.class_id"
            placeholder="请选择所属分类"
            style="width: 100%"
          >
            <el-option
              v-for="item in typeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="缩略图" prop="img">
          <Upload-img v-model="form.img"></Upload-img>
        </el-form-item>

        <el-form-item label="视频">
          <Upload-video v-model="form.video"></Upload-video>
        </el-form-item>

        <el-form-item label="排序值" prop="sort">
          <el-input-number v-model="form.sort" controls-position="right" />
        </el-form-item>

        <el-form-item label="链接">
          <el-input v-model="form.detail_url" />
        </el-form-item>

        <el-form-item label="详情">
          <div style="border: 1px solid #ccc; z-index: 1001">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editorRef"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 500px; overflow-y: hidden"
              v-model="valueHtml"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="handleCreated"
            />
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit()">{{
            params.id ? '编辑' : '新增'
          }}</el-button>
          <el-button @click="router.go(-1)">返回</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
// import { ref, onMounted } from 'vue'
import {
  articleTypeDropPullAPI,
  newArticleAPI,
  articleDetailsAPI,
  editArticleAPI
} from '@/api/article'
import { ElMessage } from 'element-plus'
import UploadImg from '@/components/UploadImg'
import UploadVideo from '@/components/UploadVideo'
import { userStore } from '@/store/index'

import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

/** 表单校验 S */
const ruleFormRef = ref(null)
const rules = ref({
  title: [{ required: true, message: '文章标题必须填写', trigger: 'blur' }],
  class_id: [{ required: true, message: '所属分类必须选择', trigger: 'blur' }],
  img: [{ required: true, message: '缩略图必须添加', trigger: 'blur' }],
  sort: [{ required: true, message: '排序值必须选择', trigger: 'blur' }]
})
/** 表单校验 E */

/** 副文本 S */
const store = userStore()

const uploadImage = 'uploadImage'

const mode = 'default' // 或 'simple'

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()

// 内容 HTML
const valueHtml = ref('')

// 工具栏配置
const toolbarConfig = {}

// 编辑器配置
const editorConfig = { placeholder: '请输入内容...', MENU_CONF: {} }
editorConfig.MENU_CONF[uploadImage] = {
  fieldName: 'file',
  // server: '/v1/img_upload',
  server: 'http://lnjsxy.college.yslts.com/v1/img_upload',
  // 自定义增加 http  header
  headers: {
    Authorization: `Bearer ${store.token}`
  },

  // 图片上传之自定义插入
  customInsert(res, insertFn) {
    const url = res.data.url
    insertFn(url)
  }
}

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

// 编辑器创建时进行赋值
const handleCreated = (editor) => {
  editorRef.value = editor // 记录 editor 实例，重要！
}

/** 副文本 E */

/** 路由处理 S */
const { params } = useRoute()
const router = useRouter()
const title = ref('编辑')
title.value = params.id ? '编辑文章' : '添加文章'
/** 路由处理 E */

/** 编辑 S */
const onEdit = async () => {
  if (!params.id) return
  const data = await articleDetailsAPI({ id: params.id })
  form.value = data
  valueHtml.value = data.detail
}
onEdit()
/** 编辑 E */
/**  添加 S  */
const form = ref({
  title: '',
  class_id: '',
  img: '',
  video: '',
  sort: 0,
  detail_url: '',
  detail: ''
})

// 所属分类
const typeArr = ref([])
const onArticleTypeDropPull = async () => {
  const data = await articleTypeDropPullAPI()
  typeArr.value = data
}
onArticleTypeDropPull()

// 添加
const onSubmit = async () => {
  ruleFormRef.value.validate(async (valid) => {
    if (!valid) return ElMessage.error('请您检查字段是否填写完整。')

    try {
      if (params.id) {
        form.value.detail = valueHtml.value
        await editArticleAPI(form.value)
        ElMessage.success('编辑文章成功')
      } else {
        form.value.detail = valueHtml.value
        await newArticleAPI(form.value)
        ElMessage.success('添加文章成功')
      }
      router.go(-1)
    } catch (error) {
      console.log(error)
    }
  })
}

/**  添加 E  */
</script>

<style lang="scss" scoped>
.box-card {
  padding-top: 20px;
}
</style>
